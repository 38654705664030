/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TabelaSubscribers from '../components/tabelas/TabelaSubscribers';
import FormSubscribers from '../components/forms/FormSubscribers';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import ConsultaSubscriber from '../components/inputs/ConsultaSubscriber';
import DeletarSubs from '../components/inputs/DeletarSubs';
import ImportCSV from '../components/inputs/ImportCSV';
import { useEventoData } from '../hooks/useEventoData';
import { Box, Grid, Typography } from '@mui/material';
import TicketsBox from '../components/dialogs/TicketsBox';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import SubscriberService from '../services/SubscriberService';
import eventBg from '../assets/event-bg.jpg';

const Evento: React.FC = () => {
  const { id } = useParams<{ id: string }>() ?? { id: '' };
  const eventId = id ?? '';
  const { evento, participantes, setParticipantes, tickets, defaultSubscribers, buscarTickets, fetchData } = useEventoData(eventId);
  const [opcaoConsulta, setOpcaoConsulta] = useState('name');
  const [openSubscriberForm, setOpenSubscriberForm] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [confirmDeleteInput, setConfirmDeleteInput] = useState('');
  const [openTicketsDialog, setOpenTicketsDialog] = useState(false);
  const [dataGauge, setDataGauge] = useState({ inscritosCredenciados: 0, inscritosNaoCredenciados: 0 });
  const [totalPresenca, setTotalPresenca] = useState({ totalCredenciados: 0, totalInscritos: 0 });

  useEffect(() => {
    const fetchTotaisInscritos = async () => {
      const response = await SubscriberService.reportTotaisCredenciados(eventId);
      setDataGauge(response.data);
      const responsePresenca = await SubscriberService.reportTotaisPresenca(eventId);
      console.log("responsePresenca", responsePresenca)
      setTotalPresenca(responsePresenca.data);
    };

    fetchTotaisInscritos();

  }, [eventId]);


  return (
    <Box>
      <Box mt={5} textAlign="center" display={'flex'} justifyContent={'space-between'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={evento?.imageUrl || eventBg}
            alt="Evento"
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              objectFit: 'cover',
              marginRight: 16,
            }}
          />
          <div>
            <Typography variant="h4" align="center" gutterBottom>
              {evento?.eventName}
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              {`${new Date(evento?.startDate).toLocaleDateString('pt-BR')} - ${new Date(evento?.endDate).toLocaleDateString('pt-BR')}`}
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginTop: -60,
            display: 'inline-flex',
          }}
        >
          <Gauge width={200} height={200} value={
            totalPresenca.totalCredenciados
          }
            valueMax={totalPresenca.totalInscritos}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 20,
                transform: 'translate(0px, 0px)',
              },
            }}
            text={
              ({ value, valueMax }) => `${value} / ${valueMax}`
            }
          />
          <Gauge width={200} height={200} value={
            dataGauge.inscritosCredenciados
          }
            valueMax={dataGauge.inscritosNaoCredenciados + dataGauge.inscritosCredenciados}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 20,
                transform: 'translate(0px, 0px)',
              },
            }}
            text={
              ({ value, valueMax }) => `${value} / ${valueMax}`
            }
          />
        </div>
      </Box>
      {/* <Box mt={5} textAlign="center">
        <InputConfirmarPresenca />
      </Box> */}
      <Box mt={5} textAlign="center">
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <ImportCSV eventId={eventId} buscarTudo={fetchData} />
          </Grid>
          <Grid item>
            <DefaultButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => setOpenSubscriberForm(true)}
            >
              Adicionar Participante
            </DefaultButton>
          </Grid>
          <Grid item>
            <DefaultButton
              variant="contained"
              color="secondary"
              onClick={() => setOpenConfirmDelete(true)}
            >
              Deletar todos
            </DefaultButton>
            <DeletarSubs
              eventId={eventId}
              buscarSubscribers={buscarTickets}
              openConfirmDelete={openConfirmDelete}
              setOpenConfirmDelete={setOpenConfirmDelete}
              confirmDeleteInput={confirmDeleteInput}
              setConfirmDeleteInput={setConfirmDeleteInput}
            />
          </Grid>
          <Grid item>
            <DefaultButton onClick={() => setOpenTicketsDialog(true)}>Tickets</DefaultButton>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5} textAlign="center">
        <FormSubscribers
          open={openSubscriberForm}
          setOpen={setOpenSubscriberForm}
          buscarTudo={buscarTickets}
          eventId={eventId}
          tickets={tickets}
        />
      </Box>
      <Box mt={5} textAlign="center">
        <TicketsBox
          tickets={tickets}
          open={openTicketsDialog}
          setOpen={setOpenTicketsDialog}
          buscarTudo={buscarTickets}
          eventId={eventId}
        />
      </Box>
      <Box mt={5}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item style={{ width: "400px" }}>
            <ConsultaSubscriber
              defaultSubscribers={defaultSubscribers}
              participantes={participantes}
              setParticipantes={setParticipantes}
              opcaoConsulta={opcaoConsulta}
              setOpcaoConsulta={setOpcaoConsulta}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={5}>
        <Typography variant="h5" align="center">Tabela de Participantes</Typography>
        <TabelaSubscribers
          subscribers={participantes}
          buscarTudo={buscarTickets}
          hasReward={evento?.hasReward}
          tickets={tickets}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Typography variant="subtitle1">
            {participantes ? `Total: ${participantes.length}` : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Evento;