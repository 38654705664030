import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Snackbar, ButtonGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventService from '../../services/EventService';
import FormEventos from '../forms/FormEventos';

interface Event {
  id: string;
  eventName: string;
  description: string;
  tags: string[] | null;
}

interface TabelaEventosProps {
  eventos: Event[];
  buscarTudo: () => void;
}

const TabelaEventos: React.FC<TabelaEventosProps> = ({ eventos, buscarTudo }) => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const handleRowClick = (eventId: string) => {
    navigate(`/evento/${eventId}`);
  };

  const handleDelete = useCallback(async (eventId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await EventService.deleteEvent(eventId);
      buscarTudo();
    } catch {
      setOpenSnackbar(true);
    }
  }, [buscarTudo]);

  const handleEdit = (event: Event, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedEvent(event);
    setOpenEditForm(true);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table aria-label="tabela de eventos">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Nome do Evento</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Descrição</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Tags</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventos.map((evento) => (
              <TableRow key={evento.id} hover onClick={() => handleRowClick(evento.id)} sx={{ cursor: 'pointer' }}>
                <TableCell align='center'>{evento.eventName}</TableCell>
                <TableCell align='center'>{evento.description}</TableCell>
                <TableCell align='center'>{evento.tags?.join(', ') || 'Sem tags'}</TableCell>
                <TableCell align='center'>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled button group"
                  >
                    <IconButton onClick={(e) => handleEdit(evento, e)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={(e) => handleDelete(evento.id, e)}>
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormEventos
        open={openEditForm}
        setOpen={setOpenEditForm}
        buscarTudo={buscarTudo}
        event={selectedEvent}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Erro ao deletar evento"
      />
    </>
  );
};

export default TabelaEventos;