import React from "react";
import { InputLabel, Select, MenuItem, Autocomplete, FormControl, TextField } from "@mui/material";


export default function ConsultaSubscriber({ defaultSubscribers, participantes, setParticipantes, opcaoConsulta, setOpcaoConsulta }: any) {
  function filtrarSubscribers(value: string) {
    if (value === '') {
      setParticipantes(defaultSubscribers)
    } else {
      const participantesFiltrados = defaultSubscribers.filter((participante: any) => {
        return participante[opcaoConsulta].toLowerCase().includes(value.toLowerCase())
      })
      setParticipantes(participantesFiltrados)
    }
  }
  return (
    <FormControl fullWidth variant="standard" className='consulta-sub'>
      <div style={{ width: "150px" }}>
        <InputLabel id="demo-simple-select-label">Opção de consulta</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          sx={{ width: "100%" }}
          id="demo-simple-select"
          value={opcaoConsulta}
          defaultValue={"name"}
          size='small'
          label="opcaoConsulta"
          onChange={(event) => setOpcaoConsulta(event.target.value)}
        >
          <MenuItem value="name">Nome</MenuItem>
          <MenuItem value="email">E-mail</MenuItem>
          <MenuItem value="document" >Documento</MenuItem>
          <MenuItem value="phone" >Telefone</MenuItem>
          <MenuItem value="transaction_code" >Código da transação</MenuItem>
        </Select>
      </div>
      <div style={
        {
          width: "200px"
        }
      }>
        <Autocomplete
          id="subscribers"
          size="small"
          sx={{ width: "100%" }}
          options={participantes}
          getOptionLabel={(option: any) => {
            return option[opcaoConsulta]
          }
          }
          onChange={(event: any) => {
            return event.target.textContent ?
              filtrarSubscribers(event.target.textContent) :
              filtrarSubscribers('')
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Pesquisar"
              placeholder=""
            />
          )}
        />
      </div>
    </FormControl>
  )
}