import { useState, useEffect, useCallback } from 'react';
import EventService from '../services/EventService';

export function useEventos() {
  const [events, setEvents] = useState([]);

  const buscarTudo = useCallback(async () => {
    try {
      const response = await EventService.getEvents();
      setEvents(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    buscarTudo();
  }, [buscarTudo]);

  return { events, buscarTudo };
}