import { AccountCircle } from "@mui/icons-material";
import { IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";



export default function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  function getUsuario() {
    const token: any = localStorage.getItem('accessToken')
    const user: any = jwtDecode(token)
    return user.name
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isAuthenticated');
    window.location.href = '/login';
  };

  return (
    <div className='text-end'>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
        <Typography variant="body1" style={{ marginLeft: '8px' }}>
          {getUsuario()}
        </Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Deslogar</MenuItem>
      </Menu>
    </div>
  )
}