import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriberService from '../services/SubscriberService';
import TicketService from '../services/TicketService';
import SideMenu from '../components/SideMenu';
import EventService from '../services/EventService';
import TabelaSubscribers from '../components/tabelas/TabelaSubscribers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DefaultButton from '../components/inputs/buttons/DefaultButton';

interface Participante {
  id: string;
  name: string;
  email: string;
}

interface EventoProps {
  id: string;
  eventName: string;
  description: string;
  startDate: string;
  duration: number;
  endDate: string;
}

interface Ticket {
  id: string;
  eventId: string;
  name: string;
  price: number;
}

function Ticket() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navega para a página anterior
  };
  const { id } = useParams<{ id: string }>() ?? { id: '' };
  const eventId = id ?? '';
  const [evento, setEvento] = useState<EventoProps | null>(null);
  const [participantes, setParticipantes] = useState<Participante[]>([]);
  const [, setTickets] = useState<Ticket[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const eventoData = await EventService.getEventById(eventId);
      const participantesData = await SubscriberService.getSubscribersByEventId(eventId);
      const ticketsData = await TicketService.getTicketsByEventId(eventId); // Ajuste conforme necessário para filtrar por evento
      setEvento(eventoData.data); // Ajuste conforme a estrutura de dados retornada
      setParticipantes(participantesData.data); // Ajuste conforme a estrutura de dados retornada
      setTickets(ticketsData.data); // Ajuste conforme a estrutura de dados retornada
    };

    fetchData();
  }, [eventId]);

  return (
    <div style={{ display: 'flex' }}>
      <SideMenu />
      <main style={{ flexGrow: 1, padding: '20px' }}>
        <DefaultButton
          startIcon={<ArrowBackIcon />}
          onClick={handleBackClick}
          variant="contained"
          color="primary"
        >
          Voltar
        </DefaultButton>
        <h1>Lista de tickets do evento {evento?.eventName}</h1>
        <h1>Ticket { }</h1>
        <h3>{evento?.description}</h3>
        <h5>{evento?.startDate && new Date(evento.startDate).toLocaleDateString('pt-br')} - {evento?.endDate && new Date(evento.endDate).toLocaleDateString('pt-br')}</h5>
        <div>
          <TabelaSubscribers subscribers={participantes} buscarTudo={function (): void {
            throw new Error('Function not implemented.');
          }} />
        </div>
      </main>
    </div>
  );
}

export default Ticket;