import * as React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const drawerWidth = 240;

export default function SideMenu() {
  const listaMenu = [
    { text: 'Eventos', route: 'eventos' },
    { text: 'Validar Presença', route: 'validar-presenca' },
    { text: 'Usuários', route: 'usuarios' },
    { text: 'Leads', route: 'leads' },
    { text: 'Relatórios', route: 'relatorios' },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          '&:first-of-type': {
            backgroundColor: '#ff373d',
          },
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <img src='/checkpoint-logo.png' alt='logo' style={{
        height: '65px',
        objectFit: 'cover',
      }} />
      <List className='align-self-center w100'>
        {listaMenu.map((rota) => (
          <ListItem key={rota.text} disablePadding className='item-side-menu'>
            <ListItemButton component={Link} to={`/${rota.route}`}>
              <ListItemText primary={rota.text} sx={{
                fontWeight: "bolder", color: "white",
                '&:first-of-type': {
                  color: 'white',
                  fontWeight: "bolder!important",
                },
              }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}