import React, { useState } from 'react';
import SubscriberService from '../../services/SubscriberService';
import { Box, TextField, FormControlLabel, Checkbox, Button, Alert } from '@mui/material';
import InputMask from 'react-input-mask';
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from 'html2canvas';

function Credenciamento() {
  const [inputValue, setInputValue] = useState('');
  const [isCNPJ, setIsCNPJ] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const qrCodeRef = React.useRef<HTMLDivElement>(null);
  const [subscriber, setSubscriber] = useState({} as any);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const cleanedValue = inputValue.replace(/\D/g, ''); // Remove all non-numeric characters
    SubscriberService.credenciamento(cleanedValue)
      .then(response => {
        setSubscriber(response.data);
        setErrorMessage(''); // Clear any previous error message
      })
      .catch(() => {
        setErrorMessage('Documento informado é inválido.');
      });

    setInputValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCNPJ(event.target.checked);
    setInputValue(''); // Clear the input value when switching between CPF and CNPJ
  };

  const getMask = () => {
    return isCNPJ ? '99.999.999/9999-99' : '999.999.999-99';
  };

  const handleSaveQRCode = async () => {
    if (qrCodeRef.current) {
      const canvas = await html2canvas(qrCodeRef.current);
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="20px" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <h1>Solicitar ticket</h1>
      <p>Insira o seu CPF ou CNPJ cadastrado para receber o QR code do seu ingresso</p>
      <FormControlLabel
        control={<Checkbox checked={isCNPJ} onChange={handleCheckboxChange} />}
        label="CNPJ"
      />
      <Box component="form" noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }} onSubmit={handleSubmit}>
        <InputMask
          mask={getMask()}
          maskChar=""
          value={inputValue}
          onChange={(e: any) => setInputValue(e.target.value)}
        >
          {() => <TextField label="Digite o documento" variant="outlined" inputRef={inputRef} fullWidth />}
        </InputMask>
        <Button variant="contained" color="primary" type="submit">
          Enviar
        </Button>
      </Box>
      {errorMessage && (
        <Alert severity="error" style={{ marginTop: '20px' }}>
          {errorMessage}
        </Alert>
      )}
      {subscriber.id && (
        <Box style={{ marginTop: '40px', textAlign: 'center' }}>
          <div ref={qrCodeRef}>
            <QRCodeSVG
              value={subscriber.id}
              size={120}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ margin: 'auto' }}
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleSaveQRCode} style={{ marginTop: '20px' }}>
            Salvar QR Code
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default Credenciamento;