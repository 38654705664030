import { useEffect, useState } from 'react';

const useAuthStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem('accessToken');
      const isAuthenticatedInStorage = localStorage.getItem('isAuthenticated') === 'true';
      setIsAuthenticated(!!token && isAuthenticatedInStorage);
      setLoading(false);
    };

    window.addEventListener('storage', checkAuthStatus);
    window.addEventListener('authChange', checkAuthStatus);

    checkAuthStatus();

    return () => {
      window.removeEventListener('storage', checkAuthStatus);
      window.removeEventListener('authChange', checkAuthStatus);
    };
  }, []);

  return { isAuthenticated, loading };
};

export default useAuthStatus;