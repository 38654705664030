import React, { useEffect, useState } from 'react';
import TabelaEventos from '../components/tabelas/TabelaEventos';
import FormEventos from '../components/forms/FormEventos';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import { useEventos } from '../hooks/useEventos';

function Eventos() {
  const { events, buscarTudo } = useEventos();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = 'Eventos';
  }, []);

  return (
    <>
      <div className='flex-container' style={{ marginBottom: '40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ margin: 0 }}>Eventos</h1>
        <DefaultButton variant='outlined' onClick={() => setOpen(true)}>Adicionar evento</DefaultButton>
      </div>
      <FormEventos open={open} setOpen={setOpen} buscarTudo={buscarTudo} />
      <TabelaEventos eventos={events} buscarTudo={buscarTudo} />
    </>
  );
}

export default Eventos;