import React from 'react';
import { Outlet } from 'react-router-dom';
import TopMenu from './components/TopMenu';

export default function DefaultLayout() {

  return (
    <div>
      <TopMenu />
      <main style={{ padding: '20px' }}>
          <Outlet />
      </main>
    </div>
  );
}