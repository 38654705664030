import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Snackbar, ButtonGroup } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import SubscriberService from '../../services/SubscriberService';
import { useState } from 'react';
import DefaultButton, { EditButton } from '../inputs/buttons/DefaultButton';
import FormSubscribers from '../forms/FormSubscribers';
import SubscriberBox from '../dialogs/SubscriberBox';
export default function TabelaSubscribers(props: any) {
  const [open, setOpen] = useState(false);
  const [openSubscriberBox, setOpenSubscriberBox] = useState(false);
  const [selectedSubscriber, setSelecterSubscriber] = useState(null);
  const handleRowClick = (subscriber: any) => {
    setSelecterSubscriber(subscriber)
    setOpenSubscriberBox(true)
  };

  function getGuests(ticket: any) {
    switch (ticket.guests) {
      case "0":
        return 'Single'
      case "1":
        return 'Double'
      case "2":
        return 'Triple'
      default:
        return ``;
    }
  }

  async function deleteSubscriber(subscriberId: string, event: any) {
    event.stopPropagation()
    await SubscriberService.deleteSubscriber(subscriberId).then(() => {
    }).catch(() => {
      setOpen(true)
    })
    props.buscarTudo()
  }

  return (
    <>
      <TableContainer component={Paper}
        sx={{ maxHeight: 600 }}>
        <Table aria-label="tabela de inscritos" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Nome</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>E-mail</TableCell>
              {/* <TableCell sx={{ fontWeight: "bold" }} align='center'>Documento</TableCell> */}
              {props.hasReward &&
                <TableCell sx={{ fontWeight: "bold" }} align='center'>Premiação</TableCell>}
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Credenciado</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Tipo de ingresso</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Especificação</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Observações</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.subscribers?.map((subscriber: any) => (
              <TableRow key={subscriber.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                onClick={() => handleRowClick(subscriber)}
                style={{ cursor: 'pointer' }}>
                <TableCell component="th" scope="row" align='center' >
                  {subscriber.name}
                </TableCell>
                <TableCell align='center'>{subscriber.email}</TableCell>
                {/* <TableCell align='center'>{subscriber.document}</TableCell> */}
                {props.hasReward &&
                  <TableCell align='center'>{subscriber.reward ? subscriber.reward : 'N/A'}</TableCell>}
                <TableCell align='center'>{subscriber.credenciado ?
                  <CheckIcon color='success' /> :
                  <ClearIcon color='error' />
                }</TableCell>
                <TableCell align='center'>{subscriber.ticket.name}</TableCell>
                <TableCell align='center'>{getGuests(subscriber.ticket)}</TableCell>
                <TableCell align='center'>{subscriber.notes ? subscriber.notes : 'N/A'}</TableCell>
                <TableCell align='center'>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled button group"
                  >
                    <EditButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelecterSubscriber(subscriber);
                        setOpen(true);
                      }}
                    >Editar</EditButton>
                    <DefaultButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteSubscriber(subscriber.id, e);
                      }}
                    >Excluir</DefaultButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SubscriberBox subscriber={selectedSubscriber} open={openSubscriberBox} setOpen={setOpenSubscriberBox} />
      <FormSubscribers open={open} setOpen={setOpen}
        buscarTudo={props.buscarTudo} subscriber={selectedSubscriber}
        eventId={selectedSubscriber ? selectedSubscriber["event"]["id"] : ""} tickets={props.tickets} />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ backgroundColor: 'red', transition: 'all 0.5s ease-in-out' }}
        onClick={() => setOpen(false)}
        message={`Você não pode deletar esse inscrito!`}
      />
    </>
  );
}