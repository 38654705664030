import axios from 'axios';
import { baseURL } from '../App';
import AuthService from './AuthService';

class TicketService {

  async getTickets(page: number = 1, limit: number = 10) {
    return axios.get(`${baseURL}/tickets`, {
      params: { page, limit }
    });
  }

  async getTicketById(id: string) {
    return axios.get(`${baseURL}/ticket/${id}`, await AuthService.getHeaderAuthorization());
  }

  async getTicketsByEventId(eventId: string) {
    return axios.get(`${baseURL}/tickets/event/${eventId}`, await AuthService.getHeaderAuthorization())
  }

  async createTicket(ticketData: object) {
    return axios.post(`${baseURL}/ticket`, ticketData, await AuthService.getHeaderAuthorization());
  }

  async updateTicket(id: string, ticketData: object) {
    return axios.put(`${baseURL}/ticket/${id}`, ticketData, await AuthService.getHeaderAuthorization());
  }

  async deleteTicket(id: string) {
    return axios.delete(`${baseURL}/ticket/${id}`, await AuthService.getHeaderAuthorization());
  }
}

export default new TicketService();