import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import TicketService from "../../services/TicketService";
import DefaultButton from "../inputs/buttons/DefaultButton";


export default function FormTickets({ open, setOpen, buscarTudo, eventId, ticket = null }: { open: boolean, setOpen: (value: boolean) => void, buscarTudo: () => void, eventId: string, ticket?: any }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [guests, setGuests] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [, setSeverity] = useState('success');

  useEffect(() => {
    if (ticket) {
      setName(ticket.name);
      setDescription(ticket.description);
      setGuests(ticket.guests);
    }
  }, [ticket])

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!name || !guests) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newTicket = {
      name: name,
      description: description,
      guests: guests,
      event: eventId
    };
    if (ticket?.id) {
      TicketService.updateTicket(ticket.id, newTicket).then(() => {
        setOpenSnackBar(true)
        setMessage('Ingresso atualizado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao atualizar ingresso')
        setSeverity('error')
      })
    } else {
      TicketService.createTicket(newTicket).then(() => {
        setOpenSnackBar(true)
        setMessage('Ingresso criado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao criar ingresso')
        setSeverity('error')
      })
    }
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar ingresso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para adicionar um novo ingresso.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Nome do ticket"
            required={true}
            type="text"
            fullWidth
            onChange={(event) => setName(event.target.value)}
            value={name}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="description"
            label="Descrição"
            type="text"
            fullWidth
            onChange={(event) => setDescription(event.target.value)}
            value={description}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="guests"
            label="Convidados"
            required={true}
            type="number"
            fullWidth
            onChange={(event) => setGuests(event.target.value)}
            value={guests}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={() => setOpen(false)} color="primary">
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={handleSubmit} color="primary">
            {ticket ? "Editar" : "Adicionar"}
          </DefaultButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        onClick={() => setOpenSnackBar(false)}
        message={message}
      />
    </>
  );
}