// eslint-disable-next-line import/named
import { Button, ButtonProps } from "@mui/material"

export default function DefaultButton({ onClick, children, type = "button", variant = "contained" }: ButtonProps) {
  return (
    <Button
      variant={variant}
      type={type}
      className='default-button'
      size='small'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export function EditButton({ onClick, children, type = "button" }: ButtonProps) {
  return (
    <Button
      variant="contained"
      type={type}
      sx={{
        backgroundColor: '#63635c',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#000000',
        }
      }}
      size='small'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}