import React, { useEffect, useRef, useState } from 'react';
import SideMenu from '../components/SideMenu';
import SubscriberService from '../services/SubscriberService';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Box, Grid, Typography, CardActions } from '@mui/material';
import FormConvidado from '../components/forms/FormConvidado';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import { useReactToPrint } from 'react-to-print';
// import QRCodePrint from '../components/inputs/QRCodePrint';
import { QRCodeSVG } from 'qrcode.react';

function Inscrito() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navega para a página anterior
  };
  const { id = '' } = useParams<{ id: string }>();
  const [subscriber, setSubscriber] = useState<any>();
  const [openFormConvidado, setOpenFormConvidado] = useState(false);
  const contentToPrint = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      const cachedSubscriber = localStorage.getItem(`subscriber_${id}`);

      if (cachedSubscriber) {
        setSubscriber(JSON.parse(cachedSubscriber));
      } else {
        const res = await SubscriberService.getSubscriberById(id);
        setSubscriber(res.data);
        localStorage.setItem(`subscriber_${id}`, JSON.stringify(res.data));
      }
    };

    fetchData();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    removeAfterPrint: false,
  });

  useEffect(() => {
    console.log('contentToPrint:', contentToPrint.current);
  }, [contentToPrint.current]);

  return (
    <div style={{ display: 'flex' }}>
      <SideMenu />
      <main style={{ flexGrow: 1, padding: '20px' }}>
        <DefaultButton
          startIcon={<ArrowBackIcon />}
          onClick={handleBackClick}
          variant="contained"
          color="primary"
        >
          Voltar
        </DefaultButton>
        <h1>Participante do evento</h1>
        {subscriber && (
          <>
            <Card sx={{ minWidth: 275, padding: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Box className="boxParticipante">
                      <div className='p28'>
                        <Typography variant="h6"><strong>Nome:</strong> {subscriber.name}</Typography>
                        <Typography variant="h6"><strong>E-mail:</strong> {subscriber.email}</Typography>
                        <Typography variant="h6"><strong>Documento:</strong> {subscriber.document}</Typography>
                        <Typography variant="h6"><strong>Telefone:</strong> {subscriber.phone}</Typography>
                        <Typography variant="h6"><strong>Código da transação:</strong> {subscriber.transaction_code}</Typography>
                        <Typography variant="h6"><strong>Credenciado:</strong> {subscriber.credenciado ? 'Sim' : 'Não'}</Typography>
                        <Typography variant="h6"><strong>Tipo de ingresso:</strong> {subscriber.ticket.name} {subscriber.ticket.guests}</Typography>
                        {subscriber.event.hasReward && <Typography variant="h6"><strong>Premiação:</strong> {subscriber.reward ? subscriber.reward : 'N/A'}</Typography>}
                        <Typography variant="h6"><strong>Observações:</strong> {subscriber.notes ? subscriber.notes : 'N/A'}</Typography>
                      </div>
                    </Box>
                    {
                      Number(subscriber.ticket.guests) > 0 && Number(subscriber.guests) < Number(subscriber.ticket.guests) && !subscriber.owner ?
                        <CardActions className='justify-center mt30'>
                          <DefaultButton
                            size="small"
                            type="submit"
                            variant="contained"
                            onClick={() => setOpenFormConvidado(true)}
                          >Cadastrar convidado</DefaultButton>
                        </CardActions>
                        : null
                    }
                  </Grid>
                  <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center" sx={{ flexWrap: "wrap" }}>
                    <div className='qrcode-sub page-break' >
                      <h1>aa</h1>
                      <QRCodeSVG
                        value={subscriber.id}
                        size={89.384}
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ margin: 'auto', alignContent: 'flex-end' }}
                      />
                      <div className='cracha'>{subscriber.name}</div>
                    </div>
                    <CardActions className='mt30'>
                      <DefaultButton
                        size="small"
                        type="submit"
                        variant="contained"
                        onClick={handlePrint}
                      >Imprimir Etiqueta</DefaultButton>
                    </CardActions>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
        {subscriber &&
          <FormConvidado
            open={openFormConvidado}
            setOpen={setOpenFormConvidado}
            subscriber={subscriber}
          />
        }
      </main>
    </div>
  );
}

export default Inscrito;