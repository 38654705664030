import { useEffect, useState } from "react";
import EventService from "../../services/EventService";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Snackbar, FormControlLabel, Checkbox } from "@mui/material";
import dayjs from 'dayjs';
import DefaultButton from "../inputs/buttons/DefaultButton";


export default function FormEventos({ open, setOpen, buscarTudo, event = null }: { open: boolean, setOpen: (value: boolean) => void, buscarTudo: () => void, event?: any }) {
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [duration, setDuration] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [hasReward, setHasReward] = useState(false);
  const [message, setMessage] = useState('');
  const [, setSeverity] = useState('success');

  useEffect(() => {
    if (event) {
      setEventName(event.eventName);
      setDescription(event.description);
      setStartDate(event.startDate);
      setDuration(event.duration);
      setHasReward(event.hasReward);
    }
  }, [event])

  const handleSubmit = (eventoForm: { preventDefault: () => void; }) => {
    eventoForm.preventDefault();
    if (!eventName || !startDate || !duration) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newEvent = {
      eventName: eventName,
      description: description,
      startDate: startDate,
      hasReward: hasReward,
      duration: duration
    };
    if (event) {
      EventService.updateEvent(event.id, newEvent).then(() => {
        setOpenSnackBar(true)
        setMessage('Evento atualizado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao atualizar evento')
        setSeverity('error')
      })
    } else {
      EventService.createEvent(newEvent).then(() => {
        setOpenSnackBar(true)
        setMessage('Evento criado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao criar evento')
        setSeverity('error')
      })
    }
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar evento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para adicionar um novo evento.
          </DialogContentText>
          <TextField
            margin="dense"
            id="eventName"
            label="Nome"
            value={eventName}
            type="text"
            fullWidth
            onChange={(event) => setEventName(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="description"
            label="Descrição"
            value={description}
            type="text"
            fullWidth
            onChange={(event) => setDescription(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="startDate"
            helperText="Data de ínicio do evento"
            type="date"
            value={new Date(startDate).toISOString().split('T')[0]}
            fullWidth
            onChange={(event) => {
              setStartDate(event.target.value)
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={hasReward}
                onChange={(event) => setHasReward(event.target.checked)}
                name="hasReward"
                color="primary"
              />
            }
            label="Tem premiação?"
          />
          <TextField
            margin="dense"
            id="duration"
            label="Duração em dias"
            value={duration}
            type="number"
            fullWidth
            onChange={(event) => setDuration(event.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DefaultButton onClick={handleSubmit}>
          {event ? "Editar" : "Adicionar"}
        </DefaultButton>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        onClick={() => setOpenSnackBar(false)}
        message={message}
      // severity={severity}
      />
    </>
  );
}
