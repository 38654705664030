import React from 'react';
import SideMenu from '../components/SideMenu';
import FormUsers from '../components/forms/FormUsers';

function Usuarios() {
  return (
    <div style={{ display: 'flex' }}>
      <SideMenu />
      <div>
        <main style={{
          flexGrow: 1,
          padding: '0px 20px 0px 20px'
        }}>
          <h1>Cadastro</h1>
          {/* Formulário de cadastro */}
          <FormUsers />
        </main>
      </div>
    </div>
  );
}

export default Usuarios;