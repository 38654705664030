import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Alert, Select, FormHelperText, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import SubscriberService from "../../services/SubscriberService";
import DefaultButton from "../inputs/buttons/DefaultButton";


export default function FormSubscribers({ open, setOpen, buscarTudo = () => { }, eventId, tickets, subscriber = null }: { open: boolean, setOpen: (value: boolean) => void, buscarTudo?: () => void, eventId: string, tickets: any, subscriber?: any }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [transaction_code, setTransactionCode] = useState('');
  const [ticket, setTicket] = useState('');
  const [phone, setPhone] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [notes, setNotes] = useState('');
  const [reward, setReward] = useState('');
  const [, setSeverity] = useState('success');

  useEffect(() => {
    if (subscriber) {
      console.log("subs:", subscriber)
      console.log("tickets:", tickets)
      setName(subscriber.name);
      setEmail(subscriber.email);
      setDocument(subscriber.document);
      setTransactionCode(subscriber.transaction_code);
      setTicket(subscriber.ticket);
      setPhone(subscriber.phone);
      setNotes(subscriber.notes);
      setReward(subscriber.reward);
    }
  }, [subscriber])

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!email || !name || !ticket) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newSubscriber = {
      name: name,
      email: email,
      document: document,
      transaction_code: transaction_code,
      phone: phone,
      ticket: ticket,
      event: eventId,
      notes: notes,
      reward: reward
    };
    if (subscriber?.id) {
      SubscriberService.updateSubscriber(subscriber.id, newSubscriber).then((res) => {
        setOpenSnackBar(true)
        setMessage('Participante atualizado com sucesso')
        setSeverity('success')
        buscarTudo()
        setOpen(false)
        console.log(res)
        return res
      }).catch((res) => {
        setOpenSnackBar(true)
        setMessage(`Erro ao atualizar inscrito, ${res.response.data.errors[0].path}: ${res.response.data.errors[0].value}`)
        setSeverity('error')
      })
    } else {
      SubscriberService.createSubscriber(newSubscriber).then((res) => {
        setOpenSnackBar(true)
        setMessage('Participante criado com sucesso')
        setSeverity('success')
        buscarTudo()
        setOpen(false)
        return res
      }).catch((res) => {
        setOpenSnackBar(true)
        setMessage(`Erro ao criar inscrito, ${res.response.data.errors[0].path}: ${res.response.data.errors[0].value}`)
        setSeverity('error')
      })
    }
  }

  function getTicketType(guests: string) {
    switch (guests) {
      case "0":
        return 'Single'
      case "1":
        return 'Double'
      case "2":
        return 'Triple'
      default:
        return ``;
    }
  }

  function getTicketName(ticket: any) {
    const tipoTicket = getTicketType(ticket?.guests)
    return `${ticket?.name} ${tipoTicket}`
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar inscrito</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para adicionar um novo inscrito.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            required
            label="Participante"
            type="text"
            fullWidth
            onChange={(event) => setName(event.target.value)}
            value={name}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="email"
            required
            label="E-mail"
            type="email"
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="document"
            label="Documento"
            type="text"
            fullWidth
            onChange={(event) => setDocument(event.target.value)}
            value={document}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="transaction_code"
            label="Código da transação"
            type="text"
            fullWidth
            onChange={(event) => setTransactionCode(event.target.value)}
            value={transaction_code}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="phone"
            label="Telefone"
            type="text"
            fullWidth
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="notes"
            label="Observações"
            type="text"
            fullWidth
            onChange={(event) => setNotes(event.target.value)}
            value={notes}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="reward"
            label="Premiação"
            type="text"
            fullWidth
            onChange={(event) => setReward(event.target.value)}
            value={reward}
            InputLabelProps={{ shrink: true }}
          />
          <Select
            id="ticket"
            label="Ingresso"
            variant="outlined"
            value={ticket || subscriber?.ticket}
            defaultValue={subscriber?.ticket}
            required
            onChange={(event) => {
              console.log("ticket", ticket)
              console.log("subscriber?.ticket", subscriber?.ticket)
              console.log("event.target.value", event.target.value)
              setTicket(event.target.value as string)
            }
            }
            fullWidth
          >
            {tickets.map((ticket: any) => (
              // <option key={ticket?.id} value={ticket?.id}>{getTicketName(ticket)}</option>
              <MenuItem key={ticket?.id} value={ticket?.id}>{getTicketName(ticket)}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Selecione o ingresso</FormHelperText>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={() => setOpen(false)} color="primary">
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={handleSubmit} color="primary">
            {subscriber ? "Editar" : "Adicionar"}
          </DefaultButton>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}