// eslint-disable-next-line import/named
import { Button, ButtonProps } from "@mui/material"

export default function SecondaryButton({ onClick, children, type = "button" }: ButtonProps) {
  return (
    <Button
      variant="contained"
      type={type}
      sx={{
        backgroundColor: '#b8b0b0',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#000000',
        }
      }}
      size='small'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export function EditButton({ onClick, children, type = "button" }: ButtonProps) {
  return (
    <Button
      variant="contained"
      type={type}
      sx={{
        backgroundColor: '#63635c',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#000000',
        }
      }}
      size='small'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}