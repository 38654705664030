import React, { useState } from 'react';
import SubscriberService from '../services/SubscriberService';
import { Box, TextField } from '@mui/material';

function ConfirmarPresenca() {

  const [inputValue, setInputValue] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [subscriber, setSubscriber] = useState({} as any);
  const handleKeyPress = (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      SubscriberService.confirmSubscriberPresence(inputValue).then(response => {
        setSubscriber(response.data);
      })

      setInputValue('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };
  console.log("TESTEEEEEEEEEE", subscriber)

  return (
    <>
      <h1>Validar Presença</h1>
      <p>Efetuar leitura do QR Code</p>
      <Box component="form" noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <TextField
          label="Digite o ID do inscrito"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          inputRef={inputRef}
        />
      </Box>
      <Box style={{ marginTop: '20px' }}>
        {subscriber.name && <p>Nome: {subscriber.name}</p>}
        {subscriber.email && <p>Email: {subscriber.email}</p>}
        {subscriber.phone && <p>Telefone: {subscriber.phone}</p>}
        {subscriber.document && <p>Documento: {subscriber.document}</p>}
      </Box>
    </>
  );
}

export default ConfirmarPresenca;