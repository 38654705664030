import { useState, useEffect, useCallback } from 'react';
import EventService from '../services/EventService';
import SubscriberService from '../services/SubscriberService';
import TicketService from '../services/TicketService';

interface EventoProps {
  event: string;
  [key: string]: any;
}

interface Subscriber {
  id: string;
  name: string;
  [key: string]: any;
}

interface Ticket {
  id: string;
  type: string;
  [key: string]: any;
}

export function useEventoData(eventId: string) {
  const [evento, setEvento] = useState<EventoProps | null>(null);
  const [participantes, setParticipantes] = useState<Subscriber[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [defaultSubscribers, setDefaultSubscribers] = useState<Subscriber[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const eventoData = await EventService.getEventById(eventId);
      const participantesData = await SubscriberService.getSubscribersByEventId(eventId);
      const ticketsData = await TicketService.getTicketsByEventId(eventId);
      setEvento(eventoData.data);
      setParticipantes(participantesData.data);
      setDefaultSubscribers(participantesData.data);
      setTickets(ticketsData.data);
    } catch (error) {
      console.error(error);
    }
  }, [eventId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const buscarTickets = useCallback(() => {
    TicketService.getTicketsByEventId(eventId).then((response) => {
      setTickets(response.data);
    });
  }, [eventId]);

  return { evento, participantes, setParticipantes, tickets, defaultSubscribers, buscarTickets, fetchData };
}