import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import UserMenu from './UserMenu';
import { Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo-rec.png';

const listaMenu = [
  { text: 'Eventos', route: 'eventos', icon: <EventOutlinedIcon fontSize="small" /> },
  { text: 'Validar Presença', route: 'validar-presenca', icon: <CheckCircleOutlineIcon fontSize="small" /> },
  { text: 'Leads', route: 'leads', icon: <PeopleOutlineIcon fontSize="small" /> },
  { text: 'Relatórios', route: 'relatorios', icon: <BarChartOutlinedIcon fontSize="small" /> },
];

export default function TopMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" 
      // className='app-bar'
      sx={{
        backgroundColor: '#ffffff',
        color: '#000000',
        boxShadow: '2px 2px 2px #00000015',
      }}
      >
      <Toolbar>
        <img src={logo} alt='logo' style={{ height: '40px', marginRight: '20px' }} />
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {listaMenu.map((rota) => (
                <MenuItem
                  key={rota.text}
                  component={Link}
                  to={`/${rota.route}`}
                  onClick={handleMenuClose}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                    {rota.icon}
                  </Box>
                  {rota.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {listaMenu.map((rota) => (
              <Typography
                key={rota.text}
                variant="h6"
                component={Link}
                to={`/${rota.route}`}
                sx={{
                  margin: '0 10px',
                  color: 'inherit',
                  textDecoration: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  transition: 'background-color 0.3s, color 0.3s',
                  fontSize: '1rem',
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: '#c9eaf773',
                    color: '#000',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                  {rota.icon}
                </Box>
                {rota.text}
              </Typography>
            ))}
          </div>
        )}
        <div>
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}