import axios from 'axios';
import { baseURL } from '../App';
import AuthService from './AuthService';
// import { await AuthService.getHeaderAuthorization() } from './AuthService';

class EventService {

  async getEvents(page: number = 1, limit: number = 10) {
    return axios.get(`${baseURL}/events`, { ...await AuthService.getHeaderAuthorization(), params: { page, limit } });
  }

  async createEvent(eventData: object) {
    return axios.post(`${baseURL}/event`, eventData, await AuthService.getHeaderAuthorization());
  }

  async getEventById(id: string) {
    return axios.get(`${baseURL}/event/${id}`, await AuthService.getHeaderAuthorization());
  }

  async updateEvent(id: string, eventData: object) {
    return axios.put(`${baseURL}/event/${id}`, eventData, await AuthService.getHeaderAuthorization());
  }

  async deleteEvent(id: string) {
    return axios.delete(`${baseURL}/event/${id}`, await AuthService.getHeaderAuthorization());
  }
}

export default new EventService();