import React, { useState } from 'react';
import { CSVImporter } from "csv-import-react";
import SecondaryButton from './buttons/SecondaryButton';
import SubscriberService from '../../services/SubscriberService';

interface Subscriber {
  name: string;
  email: string;
  phone: string;
  document: string;
  event: string;
  transaction_code: string;
  ticket: string;
  notes: string[];
  guests?: string[];
  reward?: string;
}

export default function ImportCSV({eventId, buscarTudo}: {eventId: string, buscarTudo: () => void}) {
  const [isOpen, setIsOpen] = useState(false);

  function divideSubscribersPor100(subscribers: Subscriber[]) {
    const subscribers100Array: Subscriber[][] = [];
    for (let i = 0; i < subscribers.length; i += 100) {
      subscribers100Array.push(subscribers.slice(i, i + 100));
    }
    return subscribers100Array;
  }
  // async function importarCSV(data: any[]) {
  //   let valido = true
  //   const subscribers: Subscriber[] = data.map((subscriber) => {
  //     if (subscriber[0] !== '') {
  //       const subscriberObj: Subscriber = {
  //         name: subscriber[0],
  //         email: subscriber[1],
  //         phone: subscriber[2].replace(/\D/g, ''),
  //         document: subscriber[3],
  //         transaction_code: subscriber[4],
  //         ticket: subscriber[5],
  //         notes: subscriber[6],
  //         event: eventId
  //       };

  //       if (subscriber.length >= 8) {
  //         subscriberObj.reward = subscriber[8];
  //       }

  //       return subscriberObj;
  //     }
  //   }).filter((subscriber) => subscriber !== undefined) as Subscriber[];

  //   subscribers.splice(0, 1)
  //   subscribers.forEach((subscriber, index) => {
  //     if (!validarCampos(subscriber)) {
  //       alert(`Erro de importação na linha: ${index + 2}`)
  //       valido = false
  //       return
  //     }
  //   })
  //   if (valido) {
  //     const subscribers100Array = divideSubscribersPor100(subscribers);
  //     for (const subscribersChunk of subscribers100Array) {
  //       try {
  //         console.log(subscribersChunk);
  //         // await SubscriberService.importSubscribers(subscribersChunk);
  //       } catch {
  //         alert('Erro ao importar subscribers');
  //         break;
  //       }
  //     }
  //   }
  // }

  function validarCampos(subscriber: Subscriber) {
    if (subscriber.name === '' || subscriber.email === '' || subscriber.ticket === '') {
      return false
    }
    return true
  }

  async function handleImportComplete(data: any) {
    // console.log("data",data);
    let valido = true

    const subscribers = data.rows.map((row: any, index: any) => {
        row.values.name ? row.values.name = row.values.name.toUpperCase() : row.values.name = "SEM NOME"
        row.values.event = eventId
        row.values.phone = row.values.phone.replace(/\D/g, '')
        // row.values.notes = row.values.notes ? row.values.notes.split(',') : []
        // TODO: Implementar lógica de imports nos convidados
        // row.values.guests = row.values.guests ? row.values.guests.split(',') : []
        row.values.guests = []
        if (!validarCampos(row.values)) {
          alert(`Erro de importação na linha: ${index + 2}`)
          valido = false
          return
        }
        return row.values
    })
    if (valido) {
      const subscribers100Array = divideSubscribersPor100(subscribers);
      for (const subscribersChunk of subscribers100Array) {
        try {
          await SubscriberService.importSubscribers(subscribersChunk);
        } catch {
          alert('Erro ao importar subscribers');
          break;
        }
      }
      buscarTudo()
    }
  }

  
  return (
    <div style={{ display: 'flex' }}>
    <>
    <SecondaryButton onClick={() => setIsOpen(true)}>Importar participantes</SecondaryButton>
    <CSVImporter
      modalIsOpen={isOpen}
      modalOnCloseTriggered={() => setIsOpen(false)}
      primaryColor='#ff0000'
      onComplete={(data) => handleImportComplete(data)}
      onEnded={e => console.log("eeee",e)}
      template={{
        columns: colunas,
      }}
      language='pt'
      customTranslations={traducoesImporter}
      skipHeaderRowSelection={false}
      />
  </>
  </div>
  )
}
const colunas = [
  {
    name: "Nome",
    key: "name",
    required: true,
    description: "Nome do inscrito",
    suggested_mappings: ["Nome", "name"],
  },
  {
    name: "Email",
    key: "email",
    required: true,
    description: "Email do inscrito",
    suggested_mappings: ["e-mail", "email"],
  },
  {
    name: "Telefone",
    key: "phone",
    description: "Telefone do inscrito",
    suggested_mappings: ["Telefone", "phone"],
  },
  {
    name: "Documento",
    key: "document",
    description: "Documento do inscrito",
    suggested_mappings: ["Documento", "document"],
  },
  {
    name: "Código da transação",
    key: "transaction_code",
    description: "Código da transação do inscrito",
    suggested_mappings: ["Código da transação", "transaction_code"],
  },
  {
    name: "Ticket",
    key: "ticket",
    required: true,
    description: "Ticket do inscrito",
    suggested_mappings: ["ticket_id", "ticket"],
  },
  {
    name: "Observações",
    key: "notes",
    description: "Observações do inscrito",
    suggested_mappings: ["Observações", "notes"],
  },
  {
    name: "Convidados",
    key: "guests",
    description: "Convidados do inscrito",
    suggested_mappings: ["Convidados", "guests"],
  },
  {
    name: "Premiação",
    key: "reward",
    description: "Premiação do inscrito",
    suggested_mappings: ["Premiação", "reward"],
  }
]

const traducoesImporter = {
  pt: {
    Upload: "Carregar",
    "Browse files": "Procurar arquivos",
    "Drop your file here": "Arraste seu arquivo aqui",
    "or": "ou",
    "Download Template": "Baixar modelo",
    "Select Header": "Selecione o cabeçalho",
    "Map Columns": "Mapear colunas",
    "Expected Column": "Coluna esperada",
    "Required": "Obrigatório",
    "Select Header Row": "Selecione a linha do cabeçalho",
    "Your File Column": "Sua coluna do arquivo",
    "Your Sample Data": "Seus dados de exemplo",
    "Destination Column": "Coluna de destino",
    "Include": "Incluir",
    "Back": "Voltar",
    "Submit": "Enviar",
    "Cancel": "Cancelar",
    "Continue": "Continuar",
    "Upload another file": "Carregar outro arquivo",
    "Done": "Concluído",
    "Import Successful": "Importação bem-sucedida",
  },
}