import { CircularProgress, Container, Grid, MenuItem, Select, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import EventService from "../services/EventService";
import SubscriberService from "../services/SubscriberService";
import { BarChart, Gauge, gaugeClasses, LineChart } from "@mui/x-charts";
import DefaultButton from "../components/inputs/buttons/DefaultButton";
import { CSVLink } from "react-csv";

const useEvents = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    EventService.getEvents().then((response) => {
      setEvents(response.data);
    });
  }, []);
  return events;
};

const useReportData = (selectedEvent: string, selectedReport: string, startDateTime: string, endDateTime: string, fetchIntervalData: boolean, setLoading: (loading: boolean) => void) => {
  const [chartData, setChartData] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (!selectedEvent || !selectedReport) return;

    const fetchData = async () => {
      setLoading(true);
      const allCharts: React.ReactNode[] = [];

      if (selectedReport === '0' || selectedReport === '1') {
        const totaisIncritos = await SubscriberService.reportTotaisCredenciados(selectedEvent).then(resp => resp.data);
        const dataPie = [
          { id: 0, value: totaisIncritos.inscritosCredenciados, label: `Inscritos credenciados: ${totaisIncritos.inscritosCredenciados}` },
          { id: 1, value: totaisIncritos.inscritosNaoCredenciados, label: `Inscritos não credenciados: ${totaisIncritos.inscritosNaoCredenciados}` },
        ];
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <h1>Credenciados</h1>
              <PieChart
                series={[{ data: dataPie, innerRadius: 6, cornerRadius: 4, outerRadius: 100 }]}
                width={700}
                height={300}
                viewBox={{ x: 200, y: 0, width: 700, height: 300 }}
              />
            </Grid>
          </Grid>
        );
      }

      if (selectedReport === '0' || selectedReport === '3') {
        const totaisPorTicket = await SubscriberService.reportTotaisCredenciadosPorTicket(selectedEvent).then(resp => resp.data);
        const dataPieTicket = Object.keys(totaisPorTicket).map(ticket => ({
          id: ticket,
          value: totaisPorTicket[ticket],
          label: `${ticket}: ${totaisPorTicket[ticket]}`
        }));
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <h1>Credenciados por ticket</h1>
              <PieChart
                series={[{ data: dataPieTicket, innerRadius: 6, cornerRadius: 4, outerRadius: 100 }]}
                width={700}
                height={300}
                viewBox={{ x: 200, y: 0, width: 700, height: 300 }}
              />
            </Grid>
          </Grid>
        );
      }

      if (selectedReport === '0' || selectedReport === '4') {
        const totaisPorTicketName = await SubscriberService.reportTotaisCredenciadosPorTicketNameCredenciadoENaoCredenciado(selectedEvent).then(resp => resp.data);
        allCharts.push(
          <Grid container spacing={2}>
            {totaisPorTicketName.map((data: any) => (
              <Grid item xs={6} key={data.ticket} style={{ textAlign: 'center' }}>
                <h1>Ticket {data.ticket}</h1>
                <PieChart
                  series={[{
                    data: [
                      { id: `${data.ticket}-cred`, value: data.credenciados, label: `Credenciados: ${data.credenciados}` },
                      { id: `${data.ticket}`, value: data.naoCredenciados, label: `Não credenciados: ${data.naoCredenciados}` }],
                    innerRadius: 6, cornerRadius: 4, outerRadius: 100
                  }]}
                  width={700}
                  height={300}
                  viewBox={{ x: 200, y: 0, width: 700, height: 300 }}
                />
              </Grid>
            ))}
          </Grid>
        );
      }

      if (selectedReport === '0' || selectedReport === '5') {
        const totaisPorTicketGuests = await SubscriberService.reportTotaisCredenciadosPorTicketPorGuests(selectedEvent).then(resp => resp.data.sort((a: any, b: any) => b.total - a.total));
        const dataBarTickets = totaisPorTicketGuests.map((item: any, i: number) => ({
          id: i,
          total: item.total,
          ticket: item.ticket,
        }));
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <h1>Ranking de ticket</h1>
              <BarChart
                dataset={dataBarTickets}
                xAxis={[{ scaleType: 'band', dataKey: 'ticket' }]}
                series={[{ type: 'bar', dataKey: 'total', label: 'Credenciados por ticket' }]}
                width={600}
                height={300}
              />
            </Grid>
          </Grid>
        );
      }

      if (selectedReport === '0' || selectedReport === '2') {
        const totaisIncritosPorDia = await SubscriberService.reportTotaisCredenciadosAcumuladosPorDia(selectedEvent).then(resp => resp.data);
        const dataBar = totaisIncritosPorDia.map((item: any, i: number) => ({
          id: i,
          value: item.total,
          date: item.date.split('T')[0],
          totalInscritos: item.totalCadastrados,
          totalDia: item.totalDia
        }));
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <h1>Total de credenciados por dia</h1>
              <BarChart
                dataset={dataBar}
                xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                series={[
                  { type: 'bar', dataKey: 'totalDia', label: 'Credenciados no dia' },
                  { type: 'bar', dataKey: 'value', label: 'Credenciados acumulados' },
                  { type: 'bar', dataKey: 'totalInscritos', label: 'Total de inscritos' },
                ]}
                width={600}
                height={300}
              />
            </Grid>
          </Grid>
        );
      }

      async function mountBarChartHourData(totaisPorHora: any) {
        const dataset: any = [];
        const horas = 23;
        for (let i = 0; i < horas; i++) {
          dataset[i] = {
            hour: i,
          };
          totaisPorHora.forEach((totalPorHora: any) => {
            const totalValor = totalPorHora.totalCredenciadosPorHora.find((total: any) => total.hour === i);
            dataset[i][totalPorHora.date.split('T')[0]] = totalValor ? totalValor.total : 0;
          });
        }
        return dataset;
      }

      if (selectedReport === '0' || selectedReport === '6') {
        const totaisPorHora = await SubscriberService.reportTotaisCredenciadosPorTicketPorHora(selectedEvent).then(resp => resp.data);
        const dataBarHour = await mountBarChartHourData(totaisPorHora);
        console.log("aaaa", dataBarHour);
        const seriesData = totaisPorHora.map((data: any) => ({
          id: `${data.date.split('T')[0]}`,
          label: `${data.date.split('T')[0]}`,
          dataKey: `${data.date.split('T')[0]}`,
          area: true,
          showMark: false,
        }));
        const horas = dataBarHour.map((data: any) => data.hour);
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <h1>Credenciados por hora</h1>
              <LineChart
                dataset={dataBarHour}
                xAxis={[{ data: horas, valueFormatter: (hour) => `${hour}:00` }]}
                series={seriesData}
                width={600}
                height={300}
              />
            </Grid>
          </Grid>
        );
      }

      if (selectedReport === '7' && fetchIntervalData) {
        const startTimestamp = new Date(startDateTime).getTime();
        const endTimestamp = new Date(endDateTime).getTime();
        const totaisPorIntervalo = await SubscriberService.reportPresencaEventoPorIntervalo(selectedEvent, startTimestamp.toString(), endTimestamp.toString()).then(resp => resp.data);
        allCharts.push(
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <h1>Total de Credenciados no Intervalo</h1>
              <p>Total de Inscritos Credenciados: {totaisPorIntervalo.totalInscritosCredenciados}</p>
              <p>Total de Inscritos presentes no intervalo: {totaisPorIntervalo.totalCredenciados.length}</p>
              <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                {totaisPorIntervalo.totalCredenciados.length > 0 && (
                  <CSVLink data={totaisPorIntervalo.totalCredenciados} filename={"subscribers.csv"} style={{ marginLeft: '10px' }}>
                    <DefaultButton style={{ marginLeft: '10px' }}>Baixar lista de presença</DefaultButton>
                  </CSVLink>
                )}
              </Grid>
              <Gauge width={200} height={200} value={
                totaisPorIntervalo.totalCredenciados.length
              }
                valueMax={totaisPorIntervalo.totalInscritosCredenciados}
                startAngle={-110}
                endAngle={110}
                sx={{
                  [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 20,
                    transform: 'translate(0px, 0px)',
                  },
                }}
                text={
                  ({ value, valueMax }) => `${value} / ${valueMax}`
                }
              />
            </Grid>
          </Grid>
        );
      }

      setChartData(allCharts);
      setLoading(false);
    };

    fetchData();
  }, [selectedEvent, selectedReport, startDateTime, endDateTime, fetchIntervalData]);

  return chartData;
};

export default function Relatorios() {
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [startDateTime, setStartDateTime] = useState<string>('');
  const [endDateTime, setEndDateTime] = useState<string>('');
  const [fetchIntervalData, setFetchIntervalData] = useState<boolean>(false);
  const events = useEvents();
  const chartData = useReportData(selectedEvent, selectedReport, startDateTime, endDateTime, fetchIntervalData, setLoading);

  const reports = [
    { id: '0', name: 'Todos' },
    { id: '1', name: 'Inscritos credenciados' },
    { id: '2', name: 'Inscritos não credenciados' },
    { id: '3', name: 'Inscritos por ticket' },
    { id: '4', name: 'Inscritos por ticket credenciados e não credenciados' },
    { id: '5', name: 'Inscritos por ticket por guests' },
    { id: '6', name: 'Inscritos por ticket por hora' },
    { id: '7', name: 'Inscritos presentes no intervalo de tempo' },
  ];

  const handleEventChange = (event: any) => {
    setSelectedEvent(event.target.value);
  };

  const handleReportChange = (report: any) => {
    setSelectedReport(report.target.value);
    setFetchIntervalData(false); // Reset fetchIntervalData when report changes
  };

  const handleFetchIntervalData = () => {
    setFetchIntervalData(true);
  };

  return (
    <Container maxWidth="lg" style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <h1>Relatórios</h1>
      </Grid>
      <Grid item xs={12}>
        <h2>Selecione o evento:</h2>
        <Select
          fullWidth
          label={"Eventos"}
          placeholder="EVENTOS"
          value={selectedEvent}
          onChange={handleEventChange}
        >
          {events.map((event: any) => (
            <MenuItem key={event.id} value={event.id}>{event.eventName}</MenuItem>
          ))}
        </Select>
        <Select
          fullWidth
          label={"Relatórios"}
          placeholder="REPORTS"
          value={selectedReport}
          onChange={handleReportChange}
          disabled={!selectedEvent}
        >
          {reports.map((report: any) => (
            <MenuItem key={report.id} value={report.id}>{report.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      {selectedReport === '7' && (
        <Grid item xs={12}>
          <TextField
            label="Start Date Time"
            type="datetime-local"
            value={startDateTime}
            onChange={(e) => setStartDateTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="End Date Time"
            type="datetime-local"
            value={endDateTime}
            onChange={(e) => setEndDateTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchIntervalData}
            disabled={!startDateTime || !endDateTime}
          >
            Buscar
          </Button>
        </Grid>
      )}
      <Grid item xs={12} style={{ position: 'relative', minHeight: '300px' }}>
        {loading ? (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </div>
        ) : (
          selectedReport && chartData
        )}
      </Grid>
    </Container>
  );
}

