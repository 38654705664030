import { DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Autocomplete } from "@mui/material";
import DefaultButton from "../inputs/buttons/DefaultButton";
import { useState } from "react";
import AuthService from "../../services/AuthService";


export default function FormUsers() {
  // const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [, setSeverity] = useState('success');

  function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();
    if (password !== confirmPassword) {
      setOpenSnackBar(true);
      setMessage('As senhas não coincidem.');
      setSeverity('error');
      return;
    }
    if (!name || !username || !password || !email || !role) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newUser = {
      name: name,
      username: username,
      password: password,
      email: email,
      role: role
    };
    AuthService.register(newUser.name, newUser.username, newUser.password, newUser.email, newUser.role)
      .then(() => {
        setOpenSnackBar(true)
        setMessage('Usuário criado com sucesso')
        setSeverity('success')
        // limpar campos
        limparCampos()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao criar usuário')
        setSeverity('error')
      })
  }

  function limparCampos() {
    setName('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setRole('');
  }

  const handleUsernameChange = (event: any) => {
    const value = event.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(value)) {
      setUsername(value);
    }
  };

  return (
    <>
      {/* <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title"> */}
      {/* <DialogTitle id="form-dialog-title">Adicionar ingresso</DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          Preencha os campos abaixo para adicionar um novo usuário.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Nome"
          required={true}
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          margin="dense"
          id="username"
          label="Nome de usuário"
          required={true}
          type="text"
          fullWidth
          value={username}
          onChange={handleUsernameChange}
        />
        <TextField
          margin="dense"
          id="password"
          label="Senha"
          required={true}
          type="password"
          value={password}
          fullWidth
          onChange={(event) => setPassword(event.target.value)}
        />
        <TextField
          margin="dense"
          id="confirmPassword"
          label="Confirmar senha"
          required={true}
          value={confirmPassword}
          type="password"
          onChange={(event) => setConfirmPassword(event.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="email"
          label="Email"
          required={true}
          value={email}
          type="email"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />
        <Autocomplete
          id="role"
          options={[{
            value: 'admin',
            label: 'Administrador'
          }, {
            value: 'user',
            label: 'Usuário'
          }]}
          getOptionLabel={(option) => option.label}
          fullWidth

          aria-required={true}
          onChange={(event, value) => {
            if (value) {
              setRole(value.value);
            } else {
              setRole('');
            }
          }} renderInput={(params) => <TextField {...params} label="Cargo" />}
        />
      </DialogContent>
      <DialogActions>
        {/* <DefaultButton onClick={() => setOpen(false)} color="primary">
            Cancelar
          </DefaultButton> */}
        <DefaultButton onClick={handleSubmit} color="primary">
          Adicionar
        </DefaultButton>
      </DialogActions>
      {/* </Dialog> */}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        onClick={() => setOpenSnackBar(false)}
        message={message}
      />
    </>
  );
}