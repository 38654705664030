import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import SubscriberService from "../../services/SubscriberService";
import DefaultButton from "../inputs/buttons/DefaultButton";


export default function FormConvidado({ open, setOpen, subscriber }: { open: boolean, setOpen: (value: boolean) => void, subscriber: any }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [transaction_code, setTransactionCode] = useState('');
  const [phone, setPhone] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [, setSeverity] = useState('success');

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!email || !document) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newSubscriber = {
      id: subscriber.id,
      name: name,
      email: email,
      document: document,
      transaction_code: transaction_code,
      phone: phone,
      ticket: subscriber.ticket.id,
      event: subscriber.event.id
    };
    SubscriberService.createGuest(newSubscriber).then(() => {
      setOpenSnackBar(true)
      setMessage('Convidado adicionado com sucesso')
      setSeverity('success')
      setOpen(false)
    }).catch(() => {
      setOpenSnackBar(true)
      setMessage('Erro ao adicionar convidado')
      setSeverity('error')
    })
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Adicionar inscrito</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para adicionar um novo inscrito.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            required
            label="Participante"
            type="text"
            fullWidth
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            required
            label="E-mail"
            type="email"
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            margin="dense"
            id="document"
            required
            label="Documento"
            type="text"
            fullWidth
            onChange={(event) => setDocument(event.target.value)}
          />
          <TextField
            margin="dense"
            id="transaction_code"
            label="Código da transação"
            type="text"
            fullWidth
            onChange={(event) => setTransactionCode(event.target.value)}
          />
          <TextField
            margin="dense"
            id="phone"
            label="Telefone"
            type="text"
            fullWidth
            onChange={(event) => setPhone(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={() => setOpen(false)} color="primary">
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={handleSubmit} color="primary">
            Adicionar
          </DefaultButton>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}