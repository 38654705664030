import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import SubscriberService from "../../services/SubscriberService";


export default function DeletarSubs({ eventId, buscarSubscribers, openConfirmDelete, setOpenConfirmDelete, confirmDeleteInput, setConfirmDeleteInput }: any) {

  function handleSubmitDeleteAll() {
    console.log(confirmDeleteInput)
    if (confirmDeleteInput !== 'checkpoint') {
      return
    }
    SubscriberService.deleteAllSubscribers(eventId).then(() => {
      buscarSubscribers()
      setOpenConfirmDelete(false)
    })
  }

  return (
    <Dialog
      open={openConfirmDelete}
      onClose={() => setOpenConfirmDelete(false)}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Deletar todos os participantes
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para excluir todos os participantes digite: checkpoint.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Confirmar"
          value={confirmDeleteInput}
          onChange={(event) => setConfirmDeleteInput(event.target.value)}
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirmDelete(false)}>
          Cancelar
        </Button>
        <Button type='submit'
          onClick={handleSubmitDeleteAll}
        >Excluir</Button>
      </DialogActions>
    </Dialog>
  )
}