import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Snackbar, ButtonGroup } from '@mui/material';
import TicketService from '../../services/TicketService';
import React, { useState } from 'react';
import DefaultButton, { EditButton } from '../inputs/buttons/DefaultButton';
import FormTickets from '../forms/FormTickets';

const TabelaTickets: React.FC<{ tickets: any[], buscarTudo: () => void }> = ({ tickets, buscarTudo }) => {
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [openFormTicket, setOpenFormTicket] = useState(false);

  function getGuests(ticket: any) {
    switch (ticket.guests) {
      case "0":
        return 'Single'
      case "1":
        return 'Double'
      case "2":
        return 'Triple'
      default:
        return ``;
    }
  }

  async function deleteTicket(ticketId: string, event: any) {
    event.stopPropagation()
    await TicketService.deleteTicket(ticketId)
    buscarTudo()
  }

  return (
    <>

      <TableContainer component={Paper}
        sx={{ maxHeight: 300 }}
      >
        <Table aria-label="tabela de tickets" stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "gray" }}>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Tipo de ingresso</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Especificação</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket) => (
              <TableRow key={ticket.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                style={{ cursor: 'pointer' }}>
                <TableCell
                  component="th" scope="row" align='center'>
                  {ticket.name}
                </TableCell>
                <TableCell align='center'>{getGuests(ticket)}</TableCell>
                <TableCell align='center'>
                  {/* <DefaultButton size='small' variant="contained" color="primary" onClick={(event) => deleteTicket(ticket.id, event)}>Excluir</DefaultButton> */}
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled button group"
                  >
                    <EditButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedTicket(ticket);
                        setOpenFormTicket(true);
                      }}
                    >Editar</EditButton>
                    <DefaultButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteTicket(ticket.id, e);
                      }}
                    >Excluir</DefaultButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormTickets ticket={selectedTicket} open={openFormTicket} setOpen={setOpenFormTicket} buscarTudo={buscarTudo} eventId={selectedTicket?.event?.id} />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ backgroundColor: 'red', transition: 'all 0.5s ease-in-out' }}
        onClick={() => setOpen(false)}
        message="Ticket não pode ser deletado pois possui inscritos com esse ingresso"
      />
    </>
  );
}

export default TabelaTickets