import axios from 'axios';
import { baseURL } from '../App';
import AuthService from './AuthService';

interface Subscriber {
  id?: string;
  name: string;
  email: string;
  phone: string;
  document: string;
  ticket: string;
  event: string;
  transaction_code?: string;
}
class SubscriberService {

  async createSubscriber(subscriberData: Subscriber) {
    return axios.post(`${baseURL}/subscriber`, subscriberData, await AuthService.getHeaderAuthorization());
  }

  async createGuest(subscriberData: Subscriber) {
    return axios.post(`${baseURL}/subscriber/convidado`, subscriberData, await AuthService.getHeaderAuthorization());
  }

  async getSubscribers(page: number = 1, limit: number = 10) {
    return axios.get(`${baseURL}/subscribers`, {
      ...await AuthService.getHeaderAuthorization(), params: { page, limit }
    });
  }

  async getSubscriberById(id: string) {
    return axios.get(`${baseURL}/subscriber/${id}`, await AuthService.getHeaderAuthorization());
  }

  async getSubscribersByEventId(eventId: string) {
    return axios.get(`${baseURL}/subscribers/event/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async confirmSubscriberPresence(id: string) {
    return axios.get(`${baseURL}/subscriber/confirmar-preseca/${id}`, await AuthService.getHeaderAuthorization());
  }

  async updateSubscriber(id: string, subscriberData: object) {
    return axios.put(`${baseURL}/subscriber/${id}`, subscriberData, await AuthService.getHeaderAuthorization());
  }

  async deleteSubscriber(id: string) {
    return axios.delete(`${baseURL}/subscriber/${id}`, await AuthService.getHeaderAuthorization());
  }

  async deleteAllSubscribers(eventId: string) {
    return axios.delete(`${baseURL}/subscribers-all/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async importSubscribers(subscribers: object[] | any[]) {
    return axios.post(`${baseURL}/import-subscribers`, subscribers, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciados(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosPorDia(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-por-dia/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosAcumuladosPorDia(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-acumulados-por-dia/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosPorTicket(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-por-ticket/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosPorTicketNameCredenciadoENaoCredenciado(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-por-ticket-name-credenciado-nao-credenciado/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosPorTicketPorGuests(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-por-ticket-guests/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisCredenciadosPorTicketPorHora(eventId: string) {
    return axios.get(`${baseURL}/report-totais-credenciados-por-hora/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportTotaisPresenca(eventId: string) {
    return axios.get(`${baseURL}/report-presenca-total-por-evento/${eventId}`, await AuthService.getHeaderAuthorization());
  }

  async reportPresencaEventoPorIntervalo(eventId: string, startDate: string, endDate: string) {
    return axios.post(`${baseURL}/report-presenca-evento-por-intervalo/${eventId}`, { startDate, endDate }, await AuthService.getHeaderAuthorization());
  }

  async credenciamento(document: string) {
    return axios.get(`${baseURL}/credenciamento/confirmar-preseca/${document}`);
  }
}

export default new SubscriberService();